<template>
  <v-container fluid class="h-100">
    <div v-if="hexacode === undefined || hexacode === ''" class="align-center d-flex h-100 justify-center flex-column">
      <div>No device selected</div>
      <router-link to="/devices">Go home</router-link>
    </div>
    <form v-if="properties.selectedCommand !== undefined && hexacode !== undefined && hexacode.length > 0" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-expansion-panels :model-value="[0, 1, 2]" multiple>
            <v-row>
              <v-col cols="12" md="6">
                <v-expansion-panel>
                  <v-expansion-panel-title class="bg-grey-lighten-2 text-grey-darken-2">
                    {{ properties.selectedCommand.label }} - {{ hexacode }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-progress-linear v-if="loading" indeterminate color="primary" />
                    <component :is="componentSetup" :hexacode="hexacode" />
                    <v-row>
                      <v-col cols="12" md="4" class="py-0">
                        <v-checkbox-btn v-model="highPriorityRequest" dense hide-details label="High priority" color="primary" />
                      </v-col>
                      <v-col v-if="properties.selectedCommand.cache" cols="12" md="4" class="py-0">
                        <v-checkbox-btn v-model="cacheSkipped" dense hide-details label="Skip cache" color="primary" />
                      </v-col>
                      <v-col v-if="properties.selectedCommand.fallback" cols="12" md="4" class="py-0">
                        <v-checkbox-btn v-model="fallbackEnabled" dense hide-details label="Fallback to offline channel" color="primary" />
                      </v-col>
                    </v-row>
                    <v-row class="mb-1">
                      <v-divider class="mb-2" />
                      <v-btn dark block color="green" variant="elevated" :disabled="loading===true" @click="onSubmit">Send</v-btn>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-col>
              <v-col class="pt-0 pt-md-3" cols="12" md="6">
                <v-expansion-panel>
                  <v-expansion-panel-title class="bg-grey-lighten-2 text-grey-darken-2">Last response JSON</v-expansion-panel-title>
                  <v-expansion-panel-text class="overwrite-scroll">
                    <vue-json-pretty style="max-width: 500px;" :data="lastResponse" show-double-quotes show-line show-length collapsed-on-click-brackets />
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-col>
              <v-col cols="12">
                <v-expansion-panel>
                  <v-expansion-panel-title class="bg-grey-lighten-2 text-grey-darken-2">
                    <div class="d-flex align-center">
                      <v-icon>{{ mdiCardTextOutline }}</v-icon>
                      <span>Commands monitor</span>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <devices-control-panel-terminal-view :log="logEvent" />
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-col>
            </v-row>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </form>
    <reusable-speed-dial>
      <reusable-simple-tooltip text="Trigger buffer syncronization" :location="'left'">
        <v-btn icon variant="elevated" color="primary" @click="triggerBufferSyncronization">
          <v-icon color="white">{{ mdiDatabaseSyncOutline }}</v-icon>
        </v-btn>
      </reusable-simple-tooltip>
      <reusable-simple-tooltip text="Trigger alarm syncronization" :location="'left'">
        <v-btn key="2" color="primary" icon variant="elevated" @click="triggerAlarmSyncronization">
          <v-icon color="white">{{ mdiBellRingOutline }}</v-icon>
        </v-btn>
      </reusable-simple-tooltip>
      <reusable-simple-tooltip text="Show device metrics" :location="'left'">
        <v-btn icon variant="elevated" color="primary" @click="loadDeviceMetrics()">
          <v-icon color="white">{{ mdiChartLine }}</v-icon>
        </v-btn>
      </reusable-simple-tooltip>
    </reusable-speed-dial>
    <dialog-device-commands-metrics-graph-dialog v-model="showDeviceMetricsDialog" :command-metrics="commandMetrics" :hexacode="hexacode" />
  </v-container>
</template>

<script lang="ts" setup generic="T">
import { mdiBellRingOutline, mdiCardTextOutline, mdiChartLine, mdiDatabaseSyncOutline } from '@mdi/js'
import type { Subscription } from 'rxjs'
import type { DefineComponent } from 'vue'
import { ref } from 'vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import type { DeviceType } from '~/types/Device'
import type { CommandInformation, CommandMetrics, DeviceCommandRequest, DeviceCommandResponse } from '~/types/DeviceControlPanel'
import { BufferHeaderReadExtendedRequestSchema, BufferHeaderReadRequestSchema, DeviceCommandRequestSchemaUnion, ExtendedDataWriteRequestSchema, FileSystemRequestSchema } from '~/types/DeviceControlPanelValidation'
import type { WebSocketEvent } from '~/types/WebSocketEventTypes'
import { triggerAlarmSync, triggerBufferSync } from '~/utils/backend/device-data-manager-api'
import { findDeviceResponseChartData, sendCommand } from '~/utils/backend/devices-api'
import { subscribeToDeviceCommandEvents } from '~/utils/backend/events-api'

definePageMeta({
  layout: 'device-control-panel',
  middleware: ['is-authenticated'],
  authenticationRequired: true,
})

defineExpose({ VueJsonPretty })

const properties = defineProps<{
  selectedCommand: CommandInformation
}>()

const { query } = useRoute()
const hexacode = query['hexacode'] as string
const type = query['type'] as DeviceType
const componentSetup = ref<DefineComponent | null>(null)
const loading = ref(false)
const lastResponse = ref<DeviceCommandResponse[]>([])
const showDeviceMetricsDialog = ref(false)
const { $notifications } = useNuxtApp()
const logEvent = ref<WebSocketEvent | null>(null)
const commandMetrics = ref<CommandMetrics[]>([])
const { getRawHexacode } = useTableData()
const eventsSubscription = ref<Subscription | null>(null)
const rawMessageEventsSubscription = ref<Subscription | null>(null)

const {
  getDeviceCommandNotificationEventStream,
  getDeviceRawMessageEventStream,
  getDeviceXFSProgressEventStream,
  getDeviceXFSResponseEventStream,
  getSessionSubject,
} = useWebSocketComposable()

function loadDeviceMetrics() {
  findDeviceResponseChartData(hexacode).then((r) => {
    commandMetrics.value = r.data
    showDeviceMetricsDialog.value = true
  }).catch(_e => $notifications.error('An error occured while requesting device metrics'))
}

function sendCommandRequest(request: DeviceCommandRequest) {
  loading.value = true
  const formData = new FormData()
  if (request.type === 'DeviceFileSystemRequest') {
    if (request.subCommand === 'FILE_WRITE') {
      if (request.files) {
        request.files.forEach((file) => {
          formData.append('files', file)
        })
      }
    }
  }
  const json = { ...request, ...{ files: [] } }
  formData.append('key', JSON.stringify(json))

  sendCommand(formData)
    .then((response) => {
      const deviceResponse: DeviceCommandResponse[] = response.data
      handleResponse(deviceResponse)
    })
    .catch(_e => $notifications.error('An error occurred while sending the command to the device'))
    .finally(() => {
      loading.value = false
    })
}

function handleResponse(response: DeviceCommandResponse[]) {
  lastResponse.value = response
}

const validationSchema = computed(() => {
  if (properties.selectedCommand.type === 'DeviceBufferHeaderReadRequest') {
    return toTypedSchema(BufferHeaderReadRequestSchema)
  } else if (properties.selectedCommand.type === 'DeviceBufferHeaderReadExtendedRequest') {
    return toTypedSchema(BufferHeaderReadExtendedRequestSchema)
  } else if (properties.selectedCommand.type === 'DeviceExtendedDataWriteRequest') {
    return toTypedSchema(ExtendedDataWriteRequestSchema)
  } else if (properties.selectedCommand.type === 'DeviceFileSystemRequest') {
    return toTypedSchema(FileSystemRequestSchema)
  }
  return toTypedSchema(DeviceCommandRequestSchemaUnion)
})

const { handleSubmit, values, setFieldValue, resetForm } = useForm<DeviceCommandRequest>({
  validationSchema,
  initialValues: {
    type: 'DeviceIdReadRequest',
    fallbackToOfflineChannel: true,
    cacheSkipped: false,
    highPriorityRequest: false,
    hexacode: getRawHexacode(hexacode)!,
  },
})

const onSubmit = handleSubmit((values) => {
  sendCommandRequest(values)
})

const cacheSkipped = computed<boolean>({
  get() {
    return values.cacheSkipped!
  },
  set(value) {
    setFieldValue('cacheSkipped', value)
  },
})
const highPriorityRequest = computed<boolean>({
  get() {
    return values.highPriorityRequest!
  },
  set(value) {
    setFieldValue('highPriorityRequest', value)
  },
})
const fallbackEnabled = computed<boolean>({
  get() {
    return values.fallbackToOfflineChannel!
  },
  set(value) {
    setFieldValue('fallbackToOfflineChannel', value)
  },
})

onUnmounted(() => {
  eventsSubscription.value?.unsubscribe()
  rawMessageEventsSubscription.value?.unsubscribe()
})

onMounted(() => {
  loading.value = true
  getSessionSubject().subscribe((sessionId) => {
    loading.value = false
    subscribeToDeviceCommandEvents(sessionId, hexacode)
      .then(() => {
        console.log('Successfully subscribed to device command events')
      })
      .catch((error) => {
        console.error('Error subscribing to device command events:', error)
      })
  })

  // Subscribe to device command notification events
  eventsSubscription.value = getDeviceCommandNotificationEventStream(hexacode).subscribe((event) => {
    logEvent.value = event
  })

  // Subscribe to raw message events
  rawMessageEventsSubscription.value = getDeviceRawMessageEventStream(hexacode).subscribe((event) => {
    logEvent.value = event
  })

  // Subscribe to XFS events
  getDeviceXFSResponseEventStream(getRawHexacode(hexacode!)!).subscribe((event) => {
    lastResponse.value = [event.payload]
  })

  // Subscribe to XFS progress events
  getDeviceXFSProgressEventStream(getRawHexacode(hexacode!)!).subscribe((event) => {
    lastResponse.value = [event.payload]
  })
})

function triggerBufferSyncronization() {
  triggerBufferSync(hexacode).then(() => {
    $notifications.success('Buffer sync request sent successfully')
  }).catch(() => $notifications.error('An error occured while sending the buffer sync request'))
}
function triggerAlarmSyncronization() {
  triggerAlarmSync(hexacode, type).then(() => {
    $notifications.success('Alarm sync request sent successfully')
  }).catch(() => $notifications.error('An error occured while sending the alarm sync request'))
}

watch(() => properties.selectedCommand, async (newCommand) => {
  resetForm()
  setFieldValue('type', properties.selectedCommand.type)
  setFieldValue('hexacode', getRawHexacode(hexacode)!)

  if (newCommand && newCommand.component) {
    try {
      loading.value = false
      const module = await import(`@/components/devices/control-panel/commands/${newCommand.component}.vue`)
      componentSetup.value = markRaw(module.default)
    } catch (error) {
      console.error(`Failed to load component: ${newCommand.component}`, error)
    }
  }
},
{ immediate: true })
</script>

<style lang="scss">
.overwrite-scroll {
  max-height: 400px;
  overflow-y: auto !important;
}
</style>
