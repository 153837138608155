<template>
  <dialog-generic v-model="visible" :width="650" :loading="loading">
    <template #title>
      <div>Graph for</div>
      <div class="text-subtitle-1 text-disabled"> {{ properties.hexacode }}</div>
    </template>
    <div class="row h-100">
      <div class="col-12 h-100">
        <v-chart :option="option" autoresize style="min-height: 300px" />
      </div>
    </div>
  </dialog-generic>
</template>

<script lang="ts" setup>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, type LineSeriesOption } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { provide } from 'vue'
import type { Arrayable } from '@vueuse/core'
import type { CallbackDataParams, XAXisOption } from 'echarts/types/dist/shared'
import type { ECOption } from '~/types/ECharts'
import type { CommandMetrics } from '~/types/DeviceControlPanel'

use([LegendComponent,
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent])

provide(THEME_KEY, 'light')

const properties = defineProps<{
  hexacode: string
  commandMetrics: CommandMetrics[]
}>()
const visible = defineModel<boolean>()
const loading = ref(false)

const option = ref<ECOption> ({
  legend: {},
  dataZoom: [
    { type: 'inside' },
  ],
  grid: {
    left: '3%',
    right: '10%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value} ms',
    },
  },
  series: [],
  tooltip: {
    trigger: 'axis',
    extraCssText: 'text-align: left;',
    position: function (pos, params, dom, rect, size) {
      if (size.viewSize[0] <= 450) {
        const obj: { top?: number, left?: number, right?: number } = { top: 60 }
        if (pos[0] < size.viewSize[0] / 2) {
          obj.left = 5
        } else {
          obj.right = 5
        }
        return obj
      }
      return 'inside'
    },
    formatter: function (params) {
      const colorSpan = (color: string) => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>'
      const parameters = params as CallbackDataParams[]

      let rez = '<span></p>'
      parameters.forEach((item: CallbackDataParams) => {
        const xx = '<div>' + colorSpan(item.color as string) + ' ' + item.seriesName + ': ' + item.data + 'ms</div>'
        rez += xx
      })

      return rez
    },
  },
})

function initEChartSeries() {
  const xAxis: Arrayable<XAXisOption> = []
  const legendData: string[] = []
  const lineSeries: Arrayable<LineSeriesOption> = []
  let maxSize = 0
  properties.commandMetrics.forEach((element) => {
    legendData.push(element.opCode)

    if (maxSize < element.commandDurationPoints.length) {
      maxSize = element.commandDurationPoints.length
    }

    const line: LineSeriesOption = {
      name: element.opCode,
      type: 'line',
      markPoint: {
        data: [
          {
            type: 'max',
            label: {
              formatter: '{c} ms',
            },
            name: '',
          },
          {
            type: 'min',
            label: {
              formatter: '{c} ms',
            },
            name: '',
          },
        ],
      },
      markLine: {
        data: [
          {
            type: 'average',
            label: {
              formatter: '{c} ms',
            },
          },
        ],
      },
      data: element.commandDurationPoints,
    }

    lineSeries.push(line)
  })
  const xAxisData = []
  for (let i = 0; i < maxSize; i++) {
    xAxisData.push(i)
  }
  const xAxisOption: XAXisOption = {
    type: 'category',
    boundaryGap: false,
    data: xAxisData,
  }
  xAxis.push(xAxisOption)

  option.value.legend = {
    type: 'scroll',
    orient: 'vertical',
    right: 10,
    top: 20,
    bottom: 20,
    data: legendData,
  }
  option.value.xAxis = xAxis
  option.value.series = lineSeries
}

whenever(visible, () => {
  initEChartSeries()
})
</script>
