import { z } from 'zod'

export const OpCodeSchema = z.union([
  z.literal('IR'),
  z.literal('DIR'),
  z.literal('LW'),
  z.literal('LR'),
  z.literal('PR'),
  z.literal('PW'),
  z.literal('TR'),
  z.literal('VR'),
  z.literal('CR'),
  z.literal('CE'),
  z.literal('AR'),
  z.literal('AE'),
  z.literal('AL'),
  z.literal('GR'),
  z.literal('GE'),
  z.literal('RE'),
  z.literal('RP'),
  z.literal('RS'),
  z.literal('WE'),
  z.literal('WS'),
  z.literal('BR'),
  z.literal('BW'),
  z.literal('BC'),
  z.literal('BD'),
  z.literal('BI'),
  z.literal('XBE'),
  z.literal('ER'),
  z.literal('EW'),
  z.literal('SR'),
  z.literal('SW'),
  z.literal('SC'),
  z.literal('XWR'),
  z.literal('XRD'),
  z.literal('XFS'),
  z.literal('XTI'),
  z.literal('XTS'),
  z.literal('XBH'),
])
const IR = z.literal('DeviceIdReadRequest')
const DIR = z.literal('DeviceInformationRequest')
const LW = z.literal('DeviceLicenseWriteRequest')
const LR = z.literal('DeviceLicenseReadRequest')
const PW = z.literal('DevicePasswordWriteRequest')
const PR = z.literal('DevicePasswordReadRequest')
const TR = z.literal('DeviceTimeReadRequest')
const VR = z.literal('DeviceVersionReadRequest')
const CR = z.literal('DeviceConfigurationReadRequest')
const CE = z.literal('DeviceConfigurationReadExtendedRequest')
const AR = z.literal('DeviceAlarmReadRequest')
const AE = z.literal('DeviceAlarmReadExtendedRequest')
const AL = z.literal('DeviceAlarmListRequest')
const GR = z.literal('DevicePageStatusRequest')
const GE = z.literal('DevicePageStatusExtendedRequest')
const RE = z.literal('DeviceReadExtendedRequest')
const RP = z.literal('DeviceReadPageRequest')
const RS = z.literal('DeviceReadShortRequest')
const WE = z.literal('DeviceWriteExtendedRequest')
const WS = z.literal('DeviceWriteShortRequest')
const BR = z.literal('DeviceBufferHeaderReadRequest')
const BW = z.literal('DeviceBufferHeaderWriteRequest')
const BC = z.literal('DeviceBufferHeaderClearRequest')
const BD = z.literal('DeviceBufferDataReadRequest')
const BI = z.literal('DeviceBufferInfoReadRequest')
const XBH = z.literal('DeviceBufferHeaderReadExtendedRequest')
const XBE = z.literal('DeviceBufferDataReadExtendedRequest')
const ER = z.literal('DeviceExtendedReadRequest')
const EW = z.literal('DeviceExtendedWriteRequest')
const SR = z.literal('DeviceSlaveHeaderReadRequest')
const SW = z.literal('DeviceSlaveHeaderWriteRequest')
const SC = z.literal('DeviceSlaveHeaderClearRequest')
const XWR = z.literal('DeviceExtendedDataWriteRequest')
const XRD = z.literal('DeviceExtendedDataReadRequest')
const XFS = z.literal('DeviceFileSystemRequest')
const XTI = z.literal('DeviceTimeZoneInfoRequest')
const XTS = z.literal('DeviceDateTimeSyncRequest')

export const CommandTypeSchema = z.union([
  IR,
  DIR,
  LW,
  LR,
  PW,
  PR,
  TR,
  VR,
  CR,
  CE,
  AR,
  AE,
  AL,
  GR,
  GE,
  RE,
  RP,
  RS,
  WE,
  WS,
  BW,
  BC,
  BD,
  BI,
  XBE,
  ER,
  EW,
  SR,
  SW,
  SC,
  XTI,
  XTS,
  XBH,
  XFS,
  XWR,
  BR,
  XRD,
])
export const IdStringType = z.union([z.literal('SHORT'), z.literal('LONG')])

export const BaseCommandSchema = z.object({
  hexacode: z.number(),
  highPriorityRequest: z.boolean().default(true),
  cacheSkipped: z.boolean().default(false),
  fallbackToOfflineChannel: z.boolean().default(false),
})

export const DeviceRequestSchema = z.object({
  department: z.number({ message: 'Department is required' }),
  subDepartment: z.number({ message: 'SubDepartment is required' }),
})

export const DeviceRequestWithPageSchema = z.object({
  page: z.number({ message: 'Page is required' }),
}).merge(DeviceRequestSchema)

export const DeviceRequestWithPageAndLineSchema = z.object({
  line: z.number({ message: 'Line is required' }),
}).merge(DeviceRequestWithPageSchema)

export const DeviceRequestWithDataSchema = z.object({
  data: z.number({ message: 'Data is required' }),
}).merge(DeviceRequestWithPageAndLineSchema)

export const DeviceRequestBufferSchema = z.object({
  pcNumber: z.number({ message: 'Pc number is required' }),
}).merge(DeviceRequestWithPageAndLineSchema)

export const DeviceRequestDataExtendedBufferSchema = z.object({
  maxLength: z.number({ message: 'Max length is required' }),
}).merge(DeviceRequestBufferSchema)

// COMMANDS
// IR
export const IdReadRequestSchema = BaseCommandSchema.extend({
  type: IR,
})
// DIR
export const InformationRequestSchema = BaseCommandSchema.extend({
  type: DIR,
})
// LW
export const LicenseWriteRequestSchema = BaseCommandSchema.extend({
  type: LW,
  licenseCode: z.string().min(1, { message: 'Code is required' }).default(''),
})
// LR
export const LicenseReadRequestSchema = BaseCommandSchema.extend({
  type: LR,
})
// PR
export const PasswordReadRequestSchema = BaseCommandSchema.extend({
  type: PR,
})
// PW
export const PasswordWriteRequestSchema = BaseCommandSchema.extend({
  type: PW,
  password: z.string().min(1, { message: 'Password is required' }).default(''),
})
// TR
export const TimeReadRequestSchema = BaseCommandSchema.extend({
  type: TR,
})
// VR
export const VersionReadRequestSchema = BaseCommandSchema.extend({
  type: VR,
})
// CR
export const ConfigurationReadRequestSchema = BaseCommandSchema.extend({
  type: CR,
  pickingOrder: z.number({ message: 'Picking order is required' }),
  idStringType: IdStringType.default('SHORT'),
})
// CE
export const ConfigurationReadExtendedRequestSchema = BaseCommandSchema.extend({
  type: CE,
})
// AR
export const AlarmReadRequestSchema = BaseCommandSchema.extend({
  type: AR,
})
// AE
export const AlarmReadExtendedRequestSchema = BaseCommandSchema.extend({
  type: AE,
})
// AL
export const AlarmListRequestSchema = BaseCommandSchema.merge(DeviceRequestSchema).extend({
  type: AL,
})
// GR
export const PageStatusRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageSchema).extend({
  type: GR,
})
// GE
export const PageStatusExtendedRequestSchema = BaseCommandSchema.merge(DeviceRequestSchema).extend({
  type: GE,
})
// RE
export const ReadExtendedRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageAndLineSchema).extend({
  type: RE,
})
// RP
export const ReadPageRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageSchema).extend({
  type: RP,
})
// RS
export const ReadShortRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageAndLineSchema).extend({
  type: RS,
})
// WE
export const WriteExtendedRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageAndLineSchema).extend({
  type: WE,
  decimals: z.number({ message: 'Decimals are required' }),
  value: z.number({ message: 'Value is required' }),
})
// WS
export const WriteShortRequestSchema = BaseCommandSchema.merge(DeviceRequestWithPageAndLineSchema).extend({
  type: WS,
  value: z.number({ message: 'Value is required' }),
})
// BR
export const BufferHeaderReadRequestSchema = z.discriminatedUnion('withHead', [
  BaseCommandSchema.extend({
    type: BR,
    withHead: z.literal(true),
    department: z.number({ message: 'Department is required' }),
    subDepartment: z.number({ message: 'Subdepartment is required' }),
    page: z.number({ message: 'Page is required' }),
    line: z.number({ message: 'Line is required' }),
    pcNumber: z.number({ message: 'Pc number is required' }),
  }),
  BaseCommandSchema.extend({
    type: BR,
    withHead: z.literal(false),
    pcNumber: z.number({ message: 'Pc number is required' }),
  }),
])

// BW
export const BufferHeaderWriteRequestSchema = BaseCommandSchema.merge(DeviceRequestBufferSchema).extend({
  type: BW,
  interval: z.number({ message: 'Interval is required' }),
  start: z.number({ message: 'Start is required' }),
  stor: z.boolean().default(false),
})
// BC
export const BufferHeaderClearRequestSchema = BaseCommandSchema.merge(DeviceRequestBufferSchema).extend({
  type: BC,
})
// BD
export const BufferDataReadRequestSchema = BaseCommandSchema.merge(DeviceRequestBufferSchema).extend({
  type: BD,
  time: z.string().nullable(),
})
// BI
export const BufferInfoReadRequestSchema = BaseCommandSchema.extend({
  type: BI,
})
// XBH
const withHead = z.literal(true)
const withoutHead = z.literal(false)
const xbhType = z.union([withHead, withoutHead])

const XbhCommand = BaseCommandSchema.extend({
  type: XBH,
  withHead: xbhType,
  id: z.number(),
  pcNumber: z.number({ message: 'Pc number is required' }),
  maxLength: z.number({ message: 'Max is required' }),
})

const XBHSchemaWithoutHead = XbhCommand.extend({
  withHead: withoutHead,
})
const XBHSchemaWithHead = XbhCommand.extend({
  withHead: withHead,
  department: z.number({ message: 'Department is required' }),
  subDepartment: z.number({ message: 'Subdepartment is required' }),
  page: z.number({ message: 'Page is required' }),
  line: z.number({ message: 'Line is required' }),
})
export const BufferHeaderReadExtendedRequestSchema = z.discriminatedUnion('withHead', [XBHSchemaWithoutHead, XBHSchemaWithHead])

// XBE
export const BufferHeaderDataReadExtendedRequestSchema = BaseCommandSchema.merge(DeviceRequestDataExtendedBufferSchema).extend({
  type: XBE,
  id: z.number(),
  time: z.string().nullable(),
})
// ER
export const ExtendedReadRequestSchema = BaseCommandSchema.extend({
  type: ER,
  com: z.number({ message: 'Com is required' }),
})
// EW
export const ExtendedWriteRequestSchema = BaseCommandSchema.merge(ExtendedReadRequestSchema).extend({
  type: EW,
  info: z.string().min(1, { message: 'Info is required' }).default(''),
})
// SR
export const SlaveHeaderReadRequestSchema = BaseCommandSchema.merge(DeviceRequestBufferSchema).extend({
  type: SR,
})
// SW
export const SlaveHeaderWriteRequestSchema = BaseCommandSchema.merge(BufferHeaderWriteRequestSchema).extend({
  type: SW,
})
// SC
export const SlaveHeaderClearRequestSchema = BaseCommandSchema.merge(DeviceRequestBufferSchema).extend({
  type: SC,
})
// XWR
export const ExtendedDataWriteBlockV1Schema = z.object({
  tagId: z.number({ message: 'Tag ID is required' }),
  kind: z.number({ message: 'Kind is required' }),
  times: z.number({ message: 'Times is required' }),
  amount: z.number({ message: 'Amount is required' }),
})
export const ExtendedDataWriteBlockV2Option = z.union([
  z.literal('NO_OPTIONS'),
  z.literal('COLLAR_NUMBER'),
  z.literal('LIFE_NUMBER'),
  z.literal('CALVING_DATE'),
  z.literal('RESET_VISIT'),
  z.literal('REMOVE_TRANSPONDER'),
  z.literal('NAME'),
])
const baseExtendedDataWriteBlockV2 = z.object({
  options: ExtendedDataWriteBlockV2Option.array(),
  collarNumber: z.number().optional(),
  animalLifeNumber: z.number().optional(),
  calvingDate: z.number().optional(),
  animalName: z.string().optional(),
})
const withRecipe = baseExtendedDataWriteBlockV2.extend({
  key: z.literal('withRecipe'),
  tagId: z.number({ message: 'Tag ID is required' }),
  recipeNumber: z.number({ message: 'Recipe number is required' }),
  recipeStartDate: z.number({ message: 'Recipe start date is required' }),
  recipeEndDate: z.number({ message: 'Recipe end date is required' }),
})
const withoutRecipe = baseExtendedDataWriteBlockV2.extend({
  key: z.literal('withoutRecipe'),
  tagId: z.number({ message: 'Tag ID is required' }),
  recipeNumber: z.number({ message: 'Recipe number is required' }),
  recipeStartDate: z.number().optional(),
  recipeEndDate: z.undefined().optional(),
})

export const ExtendedDataWriteBlockV2Schema = z.discriminatedUnion('key', [withRecipe, withoutRecipe])
  .superRefine((data, ctx) => {
    const regex = /^\d{8}$/
    if (data.options.includes('COLLAR_NUMBER') && !data.collarNumber) {
      ctx.addIssue({
        path: ['collarNumber'],
        message: 'Collar number is required',
        code: z.ZodIssueCode.custom,
      })
    }
    if (data.options.includes('LIFE_NUMBER') && !data.animalLifeNumber) {
      ctx.addIssue({
        path: ['animalLifeNumber'],
        message: 'Animal life number is required',
        code: z.ZodIssueCode.custom,
      })
    }
    if (data.options.includes('CALVING_DATE')) {
      if (!data.calvingDate) {
        ctx.addIssue({
          path: ['calvingDate'],
          message: 'Calving date is required',
          code: z.ZodIssueCode.custom,
        })
      } else {
        if (!regex.test(String(data.calvingDate))) {
          ctx.addIssue({
            path: ['calvingDate'],
            message: 'Calving date must be in the format YYYYMMDD',
            code: z.ZodIssueCode.custom,
          })
        }
      }
    }
    if (data.options.includes('NAME') && !data.animalName) {
      ctx.addIssue({
        path: ['animalName'],
        message: 'Animal name is required',
        code: z.ZodIssueCode.custom,
      })
    }
  })
const XWRVersion1 = z.literal('V1')
const XWRVersion2 = z.literal('V2')
export const ExtendedDataSubcomandVersion = z.union([XWRVersion1, XWRVersion2])
const firstBlock = z.literal(true)
const otherBlocks = z.literal(false)
export const firstBlockOptions = z.union([firstBlock, otherBlocks])

const AbstractXWR = BaseCommandSchema.extend({
  id: z.number(),
  type: XWR,
  version: ExtendedDataSubcomandVersion,
  dataBlockNumber: z.number({ message: 'Data block number is required' })
    .min(0, { message: 'Data block number must be between than 0-99' })
    .max(99, { message: 'Data block number must be between than 0-99' }),
})

const AbstractXWR_v1_firstBlock = AbstractXWR.extend({
  key: z.literal('V1_firstblock'),
  body: z.object({
    type: z.literal('ExtendedDataWriteSubCommandV1Body').default('ExtendedDataWriteSubCommandV1Body'),
    ubn: z.number({ message: 'Ubn is required' }),
    date: z.string().min(1, { message: 'Date is required' }).default(''),
    dataBlocks: ExtendedDataWriteBlockV1Schema.array().default([]),
  }),
})

const AbstractXWR_v1_OtherBlocks = AbstractXWR.extend({
  key: z.literal('V1_otherBlocks'),
  body: z.object({
    type: z.literal('ExtendedDataWriteSubCommandV1Body').default('ExtendedDataWriteSubCommandV1Body'),
    dataBlocks: ExtendedDataWriteBlockV1Schema.array().default([]),
  }).optional(),
})

const AbstractXWR_V2_firstBlock = AbstractXWR.extend({
  key: z.literal('V2_firstblock'),
  body: z.object({
    type: z.literal('ExtendedDataWriteSubCommandV2Body').default('ExtendedDataWriteSubCommandV2Body'),
    ubn: z.number({ message: 'Ubn is required' }),
    farmRecipeNumber: z.number({ message: 'Farm recipe number is required' }).gte(-128).lte(127),
    removePeriod: z.number({ message: 'Remove period is required' }),
    resetPeriod: z.number({ message: 'Reset period is required' }),
    startTimeNewDay: z.number({ message: 'Start time new day is required' }),
    dataBlocks: ExtendedDataWriteBlockV2Schema.array().default([]),
  }).optional(),
})
const AbstractXWR_V2_OtherBlocks = AbstractXWR.extend({
  key: z.literal('V2_otherBlocks'),
  body: z.object({
    type: z.literal('ExtendedDataWriteSubCommandV2Body').default('ExtendedDataWriteSubCommandV2Body'),
  }).optional(),
})

export const ExtendedDataWriteRequestSchema = z.discriminatedUnion('key', [AbstractXWR_v1_firstBlock, AbstractXWR_v1_OtherBlocks, AbstractXWR_V2_firstBlock, AbstractXWR_V2_OtherBlocks])

// XRD
export const ExtendedDataReadRequestSchema = BaseCommandSchema.extend({
  type: XRD,
  id: z.number(),
  version: ExtendedDataSubcomandVersion,
  dataBlockNumber: z.number({ message: 'Data block number is required' }),
})
// XTI
export const TimeZoneInfoRequestSchema = BaseCommandSchema.extend({
  type: XTI,
  id: z.number(),
})
// XTS
export const DateTimeSyncRequestSchema = TimeZoneInfoRequestSchema.extend({
  type: XTS,
  id: z.number(),
  date: z.number({ message: 'Date is required' }),
  time: z.number({ message: 'Time is required' }),
  utcOffset: z.number({ message: 'Offset from UTC is required' }),
  dstOffset: z.number({ message: 'DST Offset is required' }),
})
// XFS
const INFO = z.literal('INFO')
const FILE_READ = z.literal('FILE_READ')
const FILE_WRITE = z.literal('FILE_WRITE')
const FILE_COPY = z.literal('FILE_COPY')
const FILE_RENAME = z.literal('FILE_RENAME')
const FILE_DELETE = z.literal('FILE_DELETE')
const FILE_ATTRIBUTE_UPDATE = z.literal('FILE_ATTRIBUTE_UPDATE')
const DIRECTORY_READ = z.literal('DIRECTORY_READ')
const DIRECTORY_CHANGE = z.literal('DIRECTORY_CHANGE')
const DIRECTORY_WRITE = z.literal('DIRECTORY_WRITE')
const DIRECTORY_DELETE = z.literal('DIRECTORY_DELETE')
const FORMAT = z.literal('FORMAT')
const SELECT = z.literal('SELECT')
const SCHEDULE_DEVICE_UPDATE = z.literal('SCHEDULE_DEVICE_UPDATE')

const FileSystemInfoRequestSubCommandBody = z.literal('FileSystemInfoRequestSubCommandBody')
const FileSystemFileReadRequestSubCommandBody = z.literal('FileSystemFileReadRequestSubCommandBody')
const FileSystemFileWriteRequestSubCommandBody = z.literal('FileSystemFileWriteRequestSubCommandBody')
const FileSystemFileCopyRequestSubCommandBody = z.literal('FileSystemFileCopyRequestSubCommandBody')
const FileSystemFileRenameRequestSubCommandBody = z.literal('FileSystemFileRenameRequestSubCommandBody')
const FileSystemFileDeleteRequestSubCommandBody = z.literal('FileSystemFileDeleteRequestSubCommandBody')
const FileSystemDirectoryReadRequestSubCommandBody = z.literal('FileSystemDirectoryReadRequestSubCommandBody')
const FileSystemDirectoryChangeRequestSubCommandBody = z.literal('FileSystemDirectoryChangeRequestSubCommandBody')
const FileSystemDirectoryWriteRequestSubCommandBody = z.literal('FileSystemDirectoryWriteRequestSubCommandBody')
const FileSystemDirectoryDeleteRequestSubCommandBody = z.literal('FileSystemDirectoryDeleteRequestSubCommandBody')
const FileSystemFormatRequestSubCommandBody = z.literal('FileSystemFormatRequestSubCommandBody')
const FileSystemSelectRequestSubCommandBody = z.literal('FileSystemSelectRequestSubCommandBody')
const FileSystemScheduleDeviceUpdateRequestSubCommandBody = z.literal('FileSystemScheduleDeviceUpdateRequestSubCommandBody')
const FileSystemFileAttributeUpdateRequestSubCommandBody = z.literal('FileSystemFileAttributeUpdateRequestSubCommandBody')
const FileSystemSchema = BaseCommandSchema.extend({
  type: XFS,
  id: z.number(),
  department: z.number({ message: 'Department is required' }),
  subDepartment: z.number({ message: 'Subdepartment is required' }),
})

export const DeviceFileAttribute = z.union([z.literal('WRITE'), z.literal('READ')])
export const DeviceFileUpdateAttribute = z.union([z.literal('WRITE'), z.literal('READ'), z.literal('DIRECTORY')])

export const FileSystemRequestSchema = z.discriminatedUnion('subCommand',
  [
    FileSystemSchema.extend({
      subCommand: INFO,
      subCommandBody: z.object({
        type: FileSystemInfoRequestSubCommandBody,
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_READ,
      subCommandBody: z.object({
        type: FileSystemFileReadRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_WRITE,
      files: z.instanceof(File).array().min(1, { message: 'File is required' }).default([]),
      subCommandBody: z.object({
        type: FileSystemFileWriteRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
        attributes: DeviceFileAttribute.array().min(1, { message: 'Attributes is required' }),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_COPY,
      subCommandBody: z.object({
        type: FileSystemFileCopyRequestSubCommandBody,
        sourcePath: z.string().min(1, { message: 'Source path is required' }).default(''),
        destinationPath: z.string().min(1, { message: 'Destination path is required' }).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_RENAME,
      subCommandBody: z.object({
        type: FileSystemFileRenameRequestSubCommandBody,
        currentName: z.string().min(1, { message: 'Current name is required' }).default(''),
        newName: z.string().min(1, { message: 'New name is required' }).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_DELETE,
      subCommandBody: z.object({
        type: FileSystemFileDeleteRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FILE_ATTRIBUTE_UPDATE,
      subCommandBody: z.object({
        type: FileSystemFileAttributeUpdateRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
        attributes: DeviceFileUpdateAttribute.array().min(1, { message: 'Attributes is required' }).default([]),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: DIRECTORY_READ,
      subCommandBody: z.object({
        type: FileSystemDirectoryReadRequestSubCommandBody,
      }),
    }),
    FileSystemSchema.extend({
      subCommand: DIRECTORY_CHANGE,
      subCommandBody: z.object({
        type: FileSystemDirectoryChangeRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: DIRECTORY_WRITE,
      subCommandBody: z.object({
        type: FileSystemDirectoryWriteRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: DIRECTORY_DELETE,
      subCommandBody: z.object({
        type: FileSystemDirectoryDeleteRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: FORMAT,
      subCommandBody: z.object({
        type: FileSystemFormatRequestSubCommandBody,
        id: z.number({ message: 'Format system id is required' }),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: SELECT,
      subCommandBody: z.object({
        type: FileSystemSelectRequestSubCommandBody,
        id: z.number({ message: 'Format system id is required' }),
      }),
    }),
    FileSystemSchema.extend({
      subCommand: SCHEDULE_DEVICE_UPDATE,
      subCommandBody: z.object({
        type: FileSystemScheduleDeviceUpdateRequestSubCommandBody,
        path: z.string().min(1, { message: 'Path is required' }).max(255).default(''),
        crc: z.string().min(1, { message: 'CRC is required' }).max(5).default(''),
        timestamp: z.string().min(1, { message: 'Timestamp is required' }).default(''),
      }),
    }),
  ])

export const CommandInformationSchema = z.object({
  label: z.string(),
  active: z.boolean(),
  enabled: z.boolean(),
  cache: z.boolean(),
  fallback: z.boolean(),

  opCode: OpCodeSchema,
  type: CommandTypeSchema,
  component: z.string(),
})

export const DeviceCommandRequestSchemaUnion = z.discriminatedUnion('type', [
  IdReadRequestSchema,
  InformationRequestSchema,
  LicenseWriteRequestSchema,
  LicenseReadRequestSchema,
  PasswordWriteRequestSchema,
  PasswordReadRequestSchema,
  TimeReadRequestSchema,
  VersionReadRequestSchema,
  ConfigurationReadRequestSchema,
  ConfigurationReadExtendedRequestSchema,
  AlarmReadRequestSchema,
  AlarmReadExtendedRequestSchema,
  AlarmListRequestSchema,
  PageStatusRequestSchema,
  PageStatusExtendedRequestSchema,
  ReadExtendedRequestSchema,
  ReadPageRequestSchema,
  ReadShortRequestSchema,
  WriteExtendedRequestSchema,
  WriteShortRequestSchema,
  BufferHeaderWriteRequestSchema,
  BufferHeaderClearRequestSchema,
  BufferDataReadRequestSchema,
  BufferInfoReadRequestSchema,
  BufferHeaderDataReadExtendedRequestSchema,
  ExtendedReadRequestSchema,
  ExtendedWriteRequestSchema,
  SlaveHeaderReadRequestSchema,
  SlaveHeaderWriteRequestSchema,
  SlaveHeaderClearRequestSchema,
  ExtendedDataReadRequestSchema,
  TimeZoneInfoRequestSchema,
  DateTimeSyncRequestSchema,
])

export const DeviceCommandRequestSchema = z.union([
  DeviceCommandRequestSchemaUnion,
  BufferHeaderReadRequestSchema,
  BufferHeaderReadExtendedRequestSchema,
  ExtendedDataWriteRequestSchema,
  FileSystemRequestSchema])
