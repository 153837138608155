import type { DeviceType } from '~/types/Device'
import { api } from '~/utils/backend'

export function triggerAlarmSync(hexacode: string, type: DeviceType) {
  return api.get('/data-manager/trigger-alarm-collection', {
    params: {
      hexacode: hexacode,
      type: type,
    },
  })
}

export function triggerBufferSync(hexacode: string) {
  return api.get('/data-manager/trigger-buffer-collection', {
    params: {
      hexacode: hexacode,
    },
  })
}
