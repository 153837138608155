<template>
  <div v-resize="fit" class="terminal-view">
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="4">
        <v-select v-model="selectedLogFormat" :items="logsFormatOptions" hide-details dense label="Logs format" />
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <reusable-simple-tooltip text="Copy terminal content" :location="'top'">
          <v-btn v-if="!decodedBytesView" icon dense variant="text" color="primary" @click="copyContent()">
            <v-icon>{{ mdiContentCopy }}</v-icon>
          </v-btn>
        </reusable-simple-tooltip>
        <reusable-simple-tooltip text="Scroll to the bottom" :location="'top'">
          <v-btn icon dense variant="text" color="primary" @click="scrollToBottom()">
            <v-icon>{{ mdiSubdirectoryArrowLeft }}</v-icon>
          </v-btn>
        </reusable-simple-tooltip>
        <reusable-simple-tooltip text="Delete terminal content" :location="'top'">
          <v-btn icon dense variant="text" color="red" @click="clear()">
            <v-icon>{{ mdiDeleteForeverOutline }}</v-icon>
          </v-btn>
        </reusable-simple-tooltip>
      </v-col>
    </v-row>

    <div class="col-12">
      <div v-if="selectedLogFormat === 'bytes'" class="align-center d-flex bg-warning w-100">
        Some log messages might be missing(in case of cached records), incomplete or duplicate as data
        it's streamed directly from device connected socket
      </div>
    </div>
    <v-divider class="mt-2 pb-2" />
    <div v-show="selectedLogFormat !== 'bytesDecoded'" id="terminal" ref="terminalContainer" class="xterm-render h-100 w-100" />
  </div>
</template>

<script lang="ts" setup>
import { mdiContentCopy, mdiDeleteForeverOutline, mdiSubdirectoryArrowLeft } from '@mdi/js'
import { ref, onMounted, watch, defineProps } from 'vue'
import { DateTime } from 'luxon'
import 'xterm/css/xterm.css'
import type { ITheme } from '@xterm/xterm'
import { Terminal } from '@xterm/xterm'
import { FitAddon } from '@xterm/addon-fit'
import type { FormatOption, LogFormatType } from '~/types/TerminalView'
import type { WebSocketEvent } from '~/types/WebSocketEventTypes'

const properties = defineProps<{
  log: WebSocketEvent | null
}>()

const logsFormatOptions = ref<FormatOption[]>([
  { title: 'Decoded', value: 'decoded' },
  { title: 'Command', value: 'command' },
  { title: 'Raw', value: 'raw' },
  { title: 'Hexadecimal', value: 'hexformat' },
  { title: 'Raw bytes', value: 'bytes' },
])

const terminalContainer = ref<HTMLDivElement | null>(null)

let terminal: Terminal | null = null
const { $notifications } = useNuxtApp()
const decodedBytesView = ref(false)

const selectedLogFormat = ref<LogFormatType>('raw')
const defaultTheme: ITheme = {
  foreground: '#2c3e50',
  background: '#fff',
  cursor: 'rgba(0, 0, 0, .4)',
  black: '#000000',
  red: '#e83030',
  brightRed: '#e83030',
  green: '#42b983',
  brightGreen: '#42b983',
  brightYellow: '#ea6e00',
  yellow: '#ea6e00',
  magenta: '#e83030',
  brightMagenta: '#e83030',
  cyan: '#03c2e6',
  brightBlue: '#03c2e6',
  brightCyan: '#03c2e6',
  blue: '#03c2e6',
  white: '#d0d0d0',
  brightBlack: '#808080',
  brightWhite: '#ffffff',
  selectionBackground: '#c2c2c2',
}

onMounted(() => {
  initTerminal()
})

const fitAddon: FitAddon = new FitAddon()

function initTerminal() {
  terminal = new Terminal(
    { cols: 100,
      rows: 30,
      theme: defaultTheme,
      disableStdin: true,
      scrollback: 50000,
      fontSize: 16,
    })

  terminal.loadAddon(fitAddon)
  terminal.open(document.getElementById('terminal')!)
  nextTick(fit)
}

function addLog(log: WebSocketEvent) {
  let prefix: string = ''
  if (log.type === 'RawDeviceMessage' || log.type === 'DeviceCommandNotification') {
    if (log.payload.request) {
      prefix = DateTime.now().toFormat('[dd-LL-yyyy HH:mm:ss:SSS] >> ')
    } else {
      prefix = DateTime.now().toFormat('[dd-LL-yyyy HH:mm:ss:SSS] << ')
    }
  }
  if (log.type === 'DeviceCommandNotification') {
    if (log.payload.fromCache) {
      prefix = `\u001b[33m${prefix}\u001b[0m`
    }

    if (log.payload.serverGenerated) {
      prefix = `\u001b[34m${prefix}\u001b[0m`
    }
  }
  if (log.type === 'RawDeviceMessage' && selectedLogFormat.value === 'bytes') {
    setContent(prefix + getContent(log))
  } else if (log.type === 'DeviceCommandNotification' && selectedLogFormat.value !== 'bytes' && !decodedBytesView.value) {
    setContent(prefix + getContent(log))
  } else if (log.type === 'RawDeviceMessage' && selectedLogFormat.value === 'bytesDecoded') {
    // TO DO
  }
}

function getContent(log: WebSocketEvent): string | boolean {
  if (log.type === 'RawDeviceMessage' && selectedLogFormat.value === 'bytes') {
    const value = log.payload.content
    return value
  } else if (log.type === 'DeviceCommandNotification' && selectedLogFormat.value !== 'bytes' && !decodedBytesView.value) {
    const value = log.payload[selectedLogFormat.value as keyof typeof log.payload]
    return value
  }
  return ''
}

function setContent(value: string, ln = true) {
  const lines = value.split('\n')
  lines.forEach((line) => {
    if (terminal) {
      if (line) {
        terminal[ln ? 'writeln' : 'write'](line)
      } else {
        terminal.writeln('')
      }
    }
  })
}

const fit = async () => {
  if (terminal && terminal.element) {
    terminal.element.style.display = 'none'
    await nextTick()
    fitAddon.fit()
    terminal.element.style.display = ''
    terminal.refresh(0, terminal.rows - 1)
  }
}

function clear() {
  if (selectedLogFormat.value !== 'bytesDecoded') {
    terminal!.clear()
  }
}

function scrollToBottom() {
  if (selectedLogFormat.value !== 'bytesDecoded') {
    terminal!.scrollToBottom()
  }
}

function copyContent() {
  if (!terminal) {
    return
  }
  const selection = terminal.getSelection()
  let textToCopy = ''

  if (selection.toString().length) {
    textToCopy = selection.toString()
  } else {
    const buffer = terminal.buffer.active
    for (let i = 0; i < buffer.length; i++) {
      const line = buffer.getLine(i)
      if (line) {
        const lineContent = line.translateToString().trim()
        if (lineContent) {
          textToCopy += lineContent + '\n'
        }
      }
    }
  }
  if (!textToCopy) {
    return
  }
  try {
    navigator.clipboard.writeText(textToCopy)
    $notifications.success('Code copied to clipboard')
  } catch (e) {
    console.log(e)
    $notifications.error('An error occured, try again later')
  }
}

watch(() => properties.log, (newLog) => {
  if (newLog !== null) {
    addLog(newLog)
  }
}
)
</script>

<style lang="css" scoped>
@import "/node_modules/@xterm/xterm/css/xterm.css";
</style>

<style lang="scss" scoped>
.xterm-render {
  min-height: 350px;
}
.xterm-cursor-layer {
  display: none;
}
</style>
